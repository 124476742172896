<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="850px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="label != ''"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                >
                {{ label }}
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="primary white--text">
                    <span class="text-h5" v-if="method=='addPackage'">Registar pacote</span>
                    <span class="text-h5" v-else>Atualizar pacote</span>
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-select v-if="method == 'addPackage'"
                        label="Pacotes predefinidos"
                        :items="allPackages"
                        item-text="name"
                        item-value="id"
                        @change="definePackage"
                    ></v-select>
                    <validation-observer ref="form_invoice" v-slot="{ invalid }">
                    <v-form class="mb-16" @submit.prevent="addPackage">
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-btn @click="exportPackage" class="success">
                                    Exportar
                                </v-btn>
                            </v-col>
                        </v-row>
                        <fieldset :disabled="loading">
                        <v-row class="mt-4">
                            <v-col cols="12">
                                <validation-provider v-slot="{ errors }" vid="name" name="name" rules="required">
                                    <v-text-field
                                    v-model="myPackage.name"
                                    :error-messages="errors"
                                    label="Nome"
                                    dense outlined
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                            <validation-provider v-slot="{ errors }" vid="description" name="description" rules="required">
                                <v-textarea
                                name="input-7-1"
                                v-model="myPackage.description"
                                :error-messages="errors"
                                dense outlined
                                label="Descriçao"
                                ></v-textarea>
                            </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <validation-provider v-slot="{ errors }" vid="hours" name="hours" rules="required">
                                    <v-text-field
                                    v-model="myPackage.hours"
                                    dense outlined
                                    :error-messages="errors"
                                    label="Horas pacote"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="6">
                                <validation-provider v-slot="{ errors }" vid="hours_spent" name="hours_spent" rules="required">
                                    <v-text-field
                                    v-model="myPackage.hours_spent"
                                    :error-messages="errors"
                                    dense outlined
                                    label="Horas gastas"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                        <v-col cols="3">
                                <validation-provider v-slot="{ errors }" vid="price" name="price" rules="required">
                                    <v-text-field
                                    v-model="myPackage.price"
                                    dense outlined
                                    :error-messages="errors"
                                    label="Preço"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="3" v-if="isPackagePredifined">
                                <validation-provider v-slot="{ errors }" vid="discount" name="discount" rules="required">
                                    <v-text-field
                                    v-model="myPackage.discount"
                                    :error-messages="errors"
                                    label="Desconto (%)"
                                    dense outlined
                                    @change="calculateNewPrice"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="3">
                                <validation-provider v-slot="{ errors }" vid="Num. Fatura" name="Num. Fatura" rules="|">
                                    <v-text-field
                                    v-model="myPackage.fatura_num"
                                    :error-messages="errors"
                                    label="Fatura"
                                    dense outlined
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="3" v-if="myPackage.fatura_num">
                                <validation-provider v-slot="{ errors }" vid="Expiração" name="Expiração" rules="|">
                                    <v-menu
                                            v-model="menu3"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="myPackage.expires_at"
                                                label="Expiração fatura"
                                                prepend-icon="mdi-calendar"
                                                :error-messages="errors"
                                                dense outlined
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="myPackage.expires_at"
                                            @input="menu3 = false"
                                            :allowed-dates="(date) => date >= new Date(new Date()+1).toISOString().substr(0, 10)"
                                            ></v-date-picker>
                                        </v-menu>
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                Gravar
                                </v-btn>
                            </v-col>
                            <v-col align="center" justify="space-around">
                                <v-btn depressed color="red" class="text-white mr-5 ml-5 mb-5" @click="dialog = false">
                                Fechar
                                </v-btn>
                            </v-col>
                            </v-row>
                        </fieldset>
                    </v-form>
                    </validation-observer>
                </v-card-text>
                <v-card-title class="primary white--text">
                    Comentários
                </v-card-title>
                <v-card-text v-if="method != 'addPackage'" class="mt-4">
                    <validation-observer ref="form_comments" v-slot="{ invalid }">
                        <v-form class="mb-16" @submit.prevent="addComment">
                            <fieldset :disabled="loading">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <validation-provider v-slot="{ errors }" vid="comment" name="comment" rules="required">
                                            <v-textarea
                                            outlined
                                            v-model="commentObject.comment"
                                            :error-messages="errors"
                                            label="Comentário"
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-checkbox label="Queimar horas?" v-model="commentObject.isBurnHours"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="3" v-if="commentObject.isBurnHours">
                                        <v-autocomplete
                                            v-model="commentObject.project_id"
                                            :items="getProjects"
                                            outlined
                                            dense
                                            item-text="name"
                                            item-value="id"
                                            label="Projeto"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="3" v-if="commentObject.isBurnHours">
                                        <validation-provider  v-slot="{ errors }" vid="burned_hours" name="burned_hours" rules="required">
                                            <v-text-field
                                            v-model="commentObject.burned_hours"
                                            :error-messages="errors"
                                            label="Queimar horas"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="4" v-if="commentObject.isBurnHours">
                                        <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="commentObject.comment_date"
                                                label="Escolha a data"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="commentObject.comment_date"
                                            @input="menu2 = false"
                                            :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="12" align="center" justify="space-around">
                                        <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                        Comentar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </fieldset>
                        </v-form>
                    </validation-observer>
                    <div :key="reloadComments">
                    <v-timeline v-for="c in myPackage.comments" :key="c.id">
                        <v-timeline-item size="large" v-if="c.landlord_user == null">
                            <template v-slot:icon>
                                <v-avatar><v-icon color="white">mdi-account</v-icon></v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span>{{ c.tenant_user.name }}</span>
                            </template>
                            <v-card :class="{'diary-card': c.is_diary, 'regular-card': !c.is_diary}" class="elevation-2">
                                <v-card-title class="text-h8">
                                <small>{{ c.comment }}</small>
                                </v-card-title>
                                <v-card-text>{{formatData(c.created_at)}}</v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item size="large" v-else>
                            <template v-slot:icon>
                                <v-avatar>
                                    <v-icon color="white" v-if="c.is_diary">mdi-notebook</v-icon>
                                    <v-icon color="white" v-else-if="c.burned_hours == null">mdi-account</v-icon>
                                    <v-icon color="white" v-else>mdi-fire-circle</v-icon>
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span>{{ c.landlord_user.name }}</span>
                            </template>
                            <v-card :class="{'diary-card': c.is_diary, 'regular-card': !c.is_diary}" class="elevation-2">
                                <v-card-title class="text-h8">
                                <small>{{ c.comment }}</small>
                                </v-card-title>
                                <v-card-text>
                                    <v-row v-if="c.burned_hours != null">
                                        <v-col cols="12">
                                            <p class="font-weight-bold">Horas queimadas: {{c.burned_hours}}</p>
                                        </v-col>
                                        <v-col cols="12" v-if="c.project != null">
                                            <p class="font-weight-bold">Projeto: {{c.project.name}}</p>
                                        </v-col>
                                        <v-col cols="12" class="mt-n3" v-if="c.comment_date != null">
                                            <p class="font-weight-bold">Data: {{c.comment_date.split(' ')[0]}}</p>
                                        </v-col>
                                    </v-row>
                                    {{formatData(c.created_at)}}

                                    <div v-if="c.images && c.images.length" class="mt-3">
                                        <v-row>
                                            <v-col
                                                v-for="img in c.images"
                                                :key="img.id"
                                                cols="4"
                                                class="pa-1"
                                                >
                                                        <v-img
                                                            :src="getImageUrl(img)"
                                                            class="comment-image cursor-pointer"
                                                            contain
                                                            @click="openImageDialog(img.imagesURL)"
                                                        ></v-img>
                                            </v-col>
                                        </v-row>
                                </div>

                                </v-card-text>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <IconRemove
                                                class="mr-2"
                                                @on-submit="deleteComment(c)"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Pacote adicionado com sucesso ao cliente
        </DialogSuccess>

        <ViewImage
            :imageUrl="selectedImageUrl"
            v-model="viewImageDialog"
        />

        <ErrorSnackbar v-model="error.show" :message="error.message" />
    </v-row>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import ClientUsers from "@/api/ClientUsers.js"
import Package from "@/api/Package.js"
import Diaries from "@/api/Diaries.js"
import PackageComments from "@/api/PackageComments.js"
import IconRemove from '@/components/ui/IconRemove.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import ViewImage from '../../diaries/images/ViewImage.vue';

export default {
    components:{
        DialogSuccess,
        IconRemove,
        ErrorSnackbar,
        ViewImage
    },
    props:{
        label: {
            Type: String,
            default: ''
        },
        tenant:{
            Type: String,
            default: null
        },
        method:{
            Type: String,
            default: 'addPackage'
        },
        selectedPackage:{
            default: null
        }
    },
    data(){
        return{
            error:{
                show: false,
                message: null
            },
            menu2: false,
            reloadComments: 0,
            projects: [],
            viewImageDialog: false,
            selectedImageUrl: null,
            commentObject: {
                comment: '',
                burned_hours: '',
                isBurnHours: false,
                id_package: null,
                comment_date: null
            },
            dialog: false,
            menu3: false,
            loading: false,
            success: false,
            myPackage:{
                name: '',
                description: '',
                fatura_num: '',
                hours: '',
                hours_spent: '',
                id_package: '',
                id: '',
                price: '',
                discount: null,
                comments: null
            },
            allPackages: [],
            isPackagePredifined: false
        }
    },
    mounted(){
        this.fillBaseData()
    },
    beforeCreate(){
        Package['getAll']().then((data) => {
            this.allPackages = data.data
        }).catch(err => {
            this.loading = false;

            if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);

                return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;
        })
    },
    watch:{
        selectedPackage:{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                this.reloadComments++

                this.myPackage.id = val.id
                this.myPackage.name = val.name
                this.myPackage.description = val.description
                this.myPackage.hours = val.hours
                this.myPackage.hours_spent = val.hours_spent
                this.myPackage.id_package = val.id_package
                this.myPackage.comments = val.comments
                this.myPackage.price = val.price
                this.myPackage.discount = val.discount
                this.myPackage.fatura_num = val.fatura_num
                this.myPackage.comments = val.comments.map(comment => ({
                    ...comment,
                    images: []
                }));

                        // Buscar imagens comentario a comentário
                        val.comments.forEach((comment, index) => {
                            //console.log(`Comentário ID ${comment.id} - diary_line_id:`, comment.diary_line_id);
                            if(comment.diary_line_id){ //só vai buscar os comentários que tenham line_id, assim nºao manda line_id a null
                                Diaries.getImages({ line_id: comment.diary_line_id })
                                    .then(resp => {
                                        this.$set(this.myPackage.comments[index], 'images', resp.data);
                                    })
                                    .catch(error => {
                                        console.error(`Erro ao buscar imagens para o comentário ID ${comment.id}:`, error);
                                    });
                            } else {
                                //console.warn(`Comentário ID ${comment.id} não possui 'diary_line_id'.`);
                            }
                        });

            }
        }
    },
    methods:{
        getImageUrl(img) {
        return img.imagesURL;
    },

            openImageDialog(imgUrl) {
                this.selectedImageUrl = imgUrl;
                this.viewImageDialog = true;
            },

            closeImageDialog() {
                this.viewImageDialog = false;
                this.selectedImageUrl = null;
            },

        exportPackage(){
            // \Route::name("admin.packages.export-info")->get ("export-info/{id}/{tenant}", "ExportInfo");
            Package.exportClientPackage(this.myPackage.id, this.tenant).then((resp) =>  {
                if(resp.data.url == null){
                    this.error.message="Pacote sem dados para exportar"
                    this.error.show = true
                    return;
                }
                window.open(resp.data.url, '_blank')
            })
        },
        deleteComment(comment){


            PackageComments.delete(comment, this.tenant)
            .then(() => {


                let i = 0
                let pos = -1

                this.myPackage.comments.forEach(element => {
                    if(element.id == comment.id){
                        pos = i
                    }
                    i++
                });
                if(pos != -1)
                    this.myPackage.comments.splice(pos, 1)


                if(comment.burned_hours != null){
                    this.myPackage.hours_spent = (parseInt(this.myPackage.hours_spent) - parseInt(comment.burned_hours)) < 0 ? 0 : parseInt(this.myPackage.hours_spent) - parseInt(comment.burned_hours)
                }


                this.reloadComments++
                this.$emit('reloadPackages')
            });
        },
        fillBaseData(){
            ClientUsers.listProjects(this.tenant).then(response => {

                this.projects = response.data

            });
        },
        resetField(){
            this.myPackage = {
                name: '',
                description: '',
                hours: '',
                hours_spent: '',
                id_package: '',
                id: '',
                price: '',
                discount: null,
                comments: null
            }
        },
        calculateNewPrice(){
            let discount_value = (this.myPackage.price * this.myPackage.discount) / 100
            this.myPackage.price = parseFloat(this.myPackage.price) - parseFloat(discount_value)
        },

        formatData(d) {
        if (!d) {
                //console.error("Data is null");
            return "";
         }
        return d.split('T')[0] + ' ' + d.split('T')[1].split('.')[0];
     },

        addComment(){
            this.commentObject.id_package = this.selectedPackage.id

            PackageComments['create'](this.commentObject, this.tenant).then((response) => {
                //console.log("DATA COMENTARIO", response.data)
                let data = response.data
                this.myPackage.comments.unshift(data)
                if(this.commentObject.isBurnHours){
                    if(this.myPackage.hours_spent == null)
                        this.myPackage.hours_spent = 0
                    this.myPackage.hours_spent = parseInt(this.myPackage.hours_spent) + parseInt(this.commentObject.burned_hours)
                }
                this.commentObject = {
                    comment: '',
                    burned_hours: '',
                    isBurnHours: false,
                    id_package: null
                }
                this.reloadComments++
            }).catch(err => {
            this.loading = false;

            if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);

                return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

            });
        },
        definePackage(id){
            let parent = this
            this.allPackages.forEach(element => {
                if(element.id == id){
                    //console.log(element)
                    parent.myPackage.name = element.name
                    parent.myPackage.description = element.description
                    parent.myPackage.hours = element.hours
                    parent.myPackage.hours_spent = 0
                    parent.myPackage.id_package = element.id,
                    parent.myPackage.price = element.price
                }
            });
            this.isPackagePredifined = true
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadPackages')
        },
        openDialog(){
            this.dialog = true
        },
        addPackage(){
            ClientUsers[this.method](this.myPackage, this.tenant).then(() => {

            this.success = true;

            this.loading = false;

            this.resetField()

            }).catch(err => {
            this.loading = false;

            if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);

                return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

            });
        },
    },
    computed:{
        getProjects(){
            let aux = []
            this.projects.forEach(element => {
                if(element.hours_left > 0)
                    aux.push(element)
            });

            return aux
        }
    }
}
</script>

<style scoped>
.diary-card {
    background-color: #aec9a5;
}

.regular-card {
    background-color: #ffe0b2;
}

.comment-image {
    width: 100%;                                                       /* Faz com que a imagem ocupe toda a largura do contêiner */
    height: 100px;                                                     /* Define a altura fixa para manter o quadrado */
    object-fit: cover;                                                 /* Garante que a imagem cubra todo o espaço sem distorção */
    border-radius: 4px;                                             /* Opcional: adiciona bordas arredondadas */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Opcional: adiciona sombra para destaque */
}

.cursor-pointer:hover {
  opacity: 0.8;
}
</style>