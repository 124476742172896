<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="label != ''"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                >
                {{ label }}
                </v-btn>
            </template>
            <v-card>
                <v-toolbar
                    class="sticky-toolbar mb-4"
                    dark
                    color="primary"
                    >
                    <v-toolbar-title>
                        <span class="text-h5" v-if="method=='addCollaborator'">Registar colaborador</span>
                    <span class="text-h5" v-else>Atualizar colaborador</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <validation-observer ref="form_invoice" v-slot="{ invalid }">
                    <v-form class="mb-16" @submit.prevent="addCollaborator">
                        <fieldset :disabled="loading">
                        <v-row>
                            <v-col cols="12">
                                <validation-provider v-slot="{ errors }" vid="full_name" name="full_name" rules="required">
                                    <v-text-field
                                    v-model="myCollaborator.full_name"
                                    dense outlined
                                    :error-messages="errors"
                                    label="Nome"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider v-slot="{ errors }" vid="Fornecedor" name="Fornecedor" rules="|">
                                    <v-select
                                    v-model="myCollaborator.supplier_id"
                                    :error-messages="errors"
                                    :items="suppliers"
                                    dense outlined
                                    item-text="name"
                                    item-value="id"
                                    label="Fornecedor"
                                    ></v-select>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider v-slot="{ errors }" vid="Telemóvel" name="Telemóvel" rules="|">
                                    <v-text-field
                                        v-model="myCollaborator.telephone"
                                        :error-messages="errors"
                                        dense outlined
                                        label="Telemóvel"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider v-slot="{ errors }" vid="Email" name="Email" rules="|">
                                    <v-text-field
                                        v-model="myCollaborator.email"
                                        :error-messages="errors"
                                        dense outlined
                                        label="Email"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider v-slot="{ errors }" vid="Notas" name="Notas" rules="|">
                                    <v-textarea
                                        v-model="myCollaborator.notes"
                                        dense outlined
                                        :error-messages="errors"
                                        label="Notas"
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-row class="mt-6" align="center" justify="space-around">
                            <v-col align="center" justify="space-around">
                                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                Gravar
                                </v-btn>
                            </v-col>
                            </v-row>
                        </fieldset>
                    </v-form>
                    </validation-observer>

                    <v-divider></v-divider>
                    <v-btn
                        v-if="!myCollaborator.user_id && myCollaborator.id"
                        @click="createUser"
                        color="success"
                        class="mt-2 mx-auto"
                        :disabled = "myCollaborator.user_id"
                    >
                        Converter para User
                    </v-btn>

                </v-card-text>
            </v-card>
        </v-dialog>

        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Colaborador adicionado com sucesso ao cliente
        </DialogSuccess>
    </v-row>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Collaborator from "@/api/Collaborator.js"
import Suppliers from "@/api/Suppliers.js"
import User from "@/api/User.js";


export default {
    components:{
        DialogSuccess,
    },
    props:{
        label: {
            Type: String,
            default: ''
        },
        tenant:{
            Type: String,
            default: null
        },
        method:{
            Type: String,
            default: 'create'
        },
        selectedCollaborator:{
            default: null
        }
    },
    data(){
        return{
            dialog: false,
            loading: false,
            success: false,
            myCollaborator:{
                full_name: '',
                supplier_id: null,
                telephone: null,
                email: null,
                notes: null,
                user_id: null
            },
            suppliers: []
        }
    },
    mounted(){
        this.fillBaseData()
    },
    watch:{
        selectedCollaborator:{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                this.myCollaborator.full_name = val.full_name
                this.myCollaborator.id = val.id
                this.myCollaborator.email = val.email
                this.myCollaborator.telephone = val.telephone
                this.myCollaborator.notes = val.notes
                this.myCollaborator.user_id = val.user_id
                this.myCollaborator.supplier_id = val.supplier_id

            }
        }
    },
    methods:{
        fillBaseData(){
            Suppliers.list().then((resp)    =>  {
                this.suppliers = resp.data
            })
        },
        resetField(){
            this.myCollaborator = {
                full_name: '',
            }
        },
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadCollaborators')
        },
        openDialog(){
            this.dialog = true
        },
        addCollaborator(){
            Collaborator[this.method](this.myCollaborator, this.tenant).then(() => {

            this.success = true;

            this.loading = false;

            this.resetField()

            }).catch(err => {
            this.loading = false;

            if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);

                return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

            });
        },
        createUser() {
            console.log("DADOS", this.myCollaborator)

            const userData = {
                name: this.myCollaborator.full_name,
                email: this.myCollaborator.email,
                telephone: this.myCollaborator.telephone,
                id_collaborator: this.myCollaborator.id,
            };

            User.create(userData).then((response) => {

                const userID = response.data.id;
                console.log("NOVO USER_ID", userID);

                    this.success = true;
                    this.$emit('reloadCollaborators');
                    this.dialog = false;

            }).catch((error) => {
                console.error(error);
            });
        }
    }
}
</script>