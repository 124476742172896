<template>
  <v-card class="elevation-10">
    <v-card-text>
      <validation-observer ref="form" v-slot="{ invalid }">
        <v-form @submit.prevent="submit"  class="mb-16">
          <v-progress-linear v-if="loading"
              indeterminate
              class="global-loader"
            ></v-progress-linear>
          <fieldset :disabled="loading">
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                  <v-text-field
                    v-model="fields.name"
                    prepend-inner-icon="mdi-account-details"
                    label="Nome *"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="telephone" name="Telefone" rules="required">
                  <v-text-field
                    v-model="fields.telephone"
                    prepend-inner-icon="mdi-phone"
                    label="Telefone *"
                    clearable
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
                  <v-text-field
                    v-model="fields.email"
                    prepend-inner-icon="mdi-at"
                    label="Email *"
                    clearable
                    type="email"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="collaborator" name="Collaborator" rules="|">
                  <v-select
                    :items="collaborators"
                    :error-messages="errors"
                    label="Colaborador"
                    item-text="full_name"
                    item-value="id"
                    v-model="fields.collaborator"
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row v-if="$root.session.id != fields.id">
              <v-col cols="12">
                <validation-provider v-slot="{ errors }" vid="roles" name="Roles">
                  <multiple-select
                    v-model="fields.roles"
                    :items="roles"
                    item-text="display_name"
                    item-value="id"
                    menu-props="auto"
                    label="Roles *"
                    prepend-inner-icon="mdi-shield-account"
                    :error-messages="errors"
                  ></multiple-select>
                </validation-provider>
              </v-col>
            </v-row>
              <v-row>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="password" name="Password">
                  <PasswordMeter :password="fields.password" v-model="fields.password"
                    :error-messages="errors" return-object/>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  class="mt-n1"
                  v-model="fields.clients"
                  :items="clients"
                  item-text="name"
                  item-value="id"
                  chips
                  label="Clientes"
                  multiple
                   @blur="filtrarProjetos"
              ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  class="mt-n1"
                  v-model="fields.projects"
                  :items="projects"
                  item-text="name"
                  item-value="id"
                  chips
                  label="Projetos"
                  multiple
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-radio-group prepend-icon="mdi-compare" label="Tema" v-model="fields.theme" @change="changeTheme" row>
                  <v-radio v-for="theme in $themes" v-bind:key="theme.name" :label="theme.name" :value="theme"></v-radio>
                </v-radio-group>
                <h3></h3>
              </v-col>
            </v-row>
            <v-row>
              <template v-if="$root.session.id == $route.params.id">
                <template v-if="qrcode">
                  <v-col cols="12">
                    <div v-html="qrcode"></div>
                  </v-col>

                  <v-col cols="12" md="4">
                    <validation-provider v-slot="{ errors }" vid="two_fa_code" name="Código 2FA" rules="required">
                      <v-text-field
                        v-model="fields.two_fa_code"
                        prepend-inner-icon="mdi-numeric"
                        label="Código 2FA *"
                        clearable
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </template>

                <v-col cols="2">
                  <v-switch
                    v-model="fields.has_2fa"
                    color="success"
                    @change="toggle2Fa"
                    label="2FA"
                    hide-details
                  ></v-switch>
                </v-col>
              </template>

              <v-col cols="2">
                <v-switch
                  v-model="fields.is_active"
                  color="success"
                  :true-value="1"
                  :false-value="0"
                  label="Ativo"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="canJoinAccount">
              <v-col cols="12">
                <v-btn
                  v-if="fields.microsoft_identifier == null"
                  class="mt-4"
                  outlined
                  @click="microsoftLogin"
                >
                <div v-if="!$msal.isAuthenticated()">
                  <v-icon class="mr-2" >mdi-microsoft</v-icon>Registar conta Microsoft
                </div>
                <div v-else>
                  <v-icon class="mr-2">mdi-microsoft</v-icon>Registar {{$msal.data.user.userName}}
                </div>
                </v-btn>
                <v-btn
                  v-else
                  class="mt-4"
                  disabled
                  outlined
                >
                  <v-icon class="mr-2">mdi-microsoft</v-icon>Conta Microsoft configurada
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-col align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                  Gravar
                </v-btn>

                <v-btn v-if="!!user && !user.is_verified" depressed color="secondary" class="mr-5 ml-5 mb-5" type="button" @click="resendActivationEmail">
                  Reenviar Email de Ativação
                </v-btn>

                <v-btn depressed color="error" class="mr-5 ml-5 mb-5" type="button" @click="reset">
                  Reset Password
                </v-btn>


              </v-col>
            </v-row>
          </fieldset>
        </v-form>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Utilizador gravado com sucesso!
        </DialogSuccess>
        <v-dialog
            v-model="password_reseted"
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="h5">Reset Password</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        offset-md="3"
                      >
                      <h2 class="success--text text-center">Email enviado!</h2>
                      <v-row class="mt-6" align="center" justify="space-around">
                        <v-icon
                          large
                          class="text-center"
                          color="success"
                        >
                          mdi-checkbox-marked-circle-outline
                        </v-icon>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="password_reseted = false"
                >
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="activation_email_resent"
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="h5">Email de Ativação</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        offset-md="3"
                      >
                      <h2 class="success--text text-center">Email enviado!</h2>
                      <v-row class="mt-6" align="center" justify="space-around">
                        <v-icon
                          large
                          class="text-center"
                          color="success"
                        >
                          mdi-checkbox-marked-circle-outline
                        </v-icon>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="activation_email_resent = false"
                >
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>
<script>
import User from "@/api/User.js";
import Role from "@/api/Role.js";
import MultipleSelect from '@/components/ui/MultipleSelect.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Collaborators from "@/api/Collaborator.js"
import Client from "@/api/Client.js"
import Project from "@/api/Project.js"
import { msalMixin } from 'vue-msal';
import PasswordMeter from '@/components/ui/PasswordMeter.vue';

export default {
  components: {
    MultipleSelect,
    DialogSuccess,
    PasswordMeter
  },
  mixins: [msalMixin],
  props: {
   user: Object,
   method: String,
   disabled: Boolean,
 },
 watch: {
  '$route.params.id'() {
    let parent = this
    User.find(this.$route.params.id)
      .then((r) => {
        parent.fields = r.data
      })
    },
    user: {
      immediate: true,
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        data.roles = data.roles.map((item) => { return item.id});

        this.fields = data;

        this.fields.id = data.id;

        this.fields.theme = this.$themes.find((item) => data.theme == null ? false : item.name == data.theme.name)

        if(this.fields.theme == undefined || this.fields.theme.length == 0){
          this.fields.theme = this.$themes[0];
        }
      }
    },
  },
  beforeCreate(){
      Client['list'](null).then((data) => {

      this.clients = data.data

      this.loading = false;

      }).catch(err => {
      this.loading = false;

      if(err.response.status == 422) {

          this.$refs.form.setErrors(err.response.data.errors);

          return;
      }

      this.error.title = "Erro " + err.response.status;

      this.error.message = err.response.data.message;

      });
  },
  data: () => ({
    microsoftRegister: false,
    clients: [],
    projects: [],
    collaborators: [],
    fields: {
      id: null,
      name: null,
      email:null,
      telephone:null,
      is_active:0,
      has_2fa: null,
      two_fa_code: null,
      roles:[],
      theme: null,
      password: null,
      collaborator: null,
      clients: [],
      projects: [],
      microsoft_identifier: null
    },
    qrcode:null,
    loading:false,
    error: {
      title: '',
      message: '',
    },
    roles: [],
    password_reseted:false,
    activation_email_resent:false,
    success: false,
  }),
  mounted(){
    this.fillBaseData();

    if(this.fields.id == this.$root.session.id)
      this.microsoftRegister = true


  },
  methods:{
     microsoftLogin(){
      if(this.$msal.isAuthenticated()){
        if(this.$msal.data.user.userName == this.$root.session.email){
          User.registerExternalLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid, 'microsoft', this.$root.session.id)
            .then(() => {

              this.$cookies.remove('addMicrosoftAccount');
              window.location.reload()
            }).catch(err => {

              if(err.response.status == 422) {

                this.$refs.login.setErrors(err.response.data.errors);

                return;
              }
          });
        }
      }else this.$msal.signIn()
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/internal_management/users');
    },
    changeTheme(theme){
      this.setTheme(theme)

      this.fields.theme = theme;
    },
    fillBaseData(){
      Role.list().then(({data}) => {
        this.roles = data;
      });
      Collaborators.list().then(({data}) => {
        this.collaborators = data
      });
    },
    toggle2Fa()
    {
      if(this.fields.has_2fa){
          User.enable2FA()
          .then(() => {
            this.render2Fa()
          });

          return;
      }

      User.disable2FA()
          .then(() => {
            this.qrcode = null;
          });
    },
    render2Fa(){
      User.get2FA()
        .then(({data}) => {
          this.qrcode = data.svg;
        });
    },
    reset () {
      User.reset(this.user.email)
          .then(() => {

              this.password_reseted = true;
          });
    },
    resendActivationEmail() {
      User.resendActivationEmail(this.user.id)
        .then(() => {

            this.activation_email_resent = true;
        });
    },

    filtrarProjetos() {
      const clientIds = this.fields.clients;
      console.log("CLIENTES")
      console.log(clientIds)
      if (clientIds.length === 0) {
        this.projects = [];
        return;
      }
      // Obtem os projetos com base nos clientes selecionados
      Project.filteredMultiple({ client_id: clientIds }).then(({ data }) => {
        this.projects = data;
      });
    },

    submit() {

      this.$refs.form.validate().then((result) => {
        this.loading = true;

        if(!result){
          this.loading = false;
          return;
        }

        if(this.fields.password != '')
          this.fields.password = this.$encryptValue(this.fields.password)


        User[this.method](this.fields, this.fields.id).then(({data}) => {

          this.success = true;

          this.loading = false;

          if(data.id == this.$root.session.id){
            let user = User.getInstance(data);

            this.$root.session = user;

            return;
          }

          this.setTheme(this.$root.session.theme);

        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);

              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
          this.fields.password = ''
      })
    }
  },
  computed: {
    canJoinAccount(){
      if(Object.keys(this.$route.params).length == 0)
        return true

      return false
    }
  }
};
</script>
