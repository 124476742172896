<template>
  <v-container fluid>
    <v-breadcrumbs
      large
      :items="crumbs"
      divider="/"
      class="pl-0"
    ></v-breadcrumbs>

    <h1 class="primary--text">
      <v-icon color="primary">mdi-account-multiple</v-icon> <span>Gestão de Diárias</span>
      <ExportExcelModal
        v-if="$root.session.hasPermission(['super', 'diaries.approve'])"
      />

      <DiariesFilter
        v-if="$root.session.hasPermission(['super', 'diaries.approve'])"
        :users="activeUsers"
        :initial-selected-user="selectedUser"
        :initial-show-all="showAll"
        @filter-changed="filterChange"
      />
    </h1>
    <div style="position:relative">
      <v-container>
        <v-speed-dial
          absolute
          v-model="speedDial"
          top
          right
          direction="bottom"
          :open-on-hover="true"
        >
          <template v-slot:activator>
            <v-tooltip right color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  v-model="speedDial"
                  color="primary"
                  dark
                  x-large
                  fab
                >
                  <v-icon v-if="speedDial">
                    mdi-close
                  </v-icon>
                  <v-icon v-else>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <span>Opções</span>
            </v-tooltip>
          </template>
          <v-tooltip right color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs" v-on="on"
                fab
                dark
                small
                color="info"
                large
                @click="searching = true"
              >
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <span>Pesquisar</span>
          </v-tooltip>
          <v-tooltip right color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs" v-on="on"
                fab
                dark
                small
                color="secondary"
                large
                to="/diaries/create"
                v-if="$root.session.hasPermission(['super', 'diaries.write'])"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Registar</span>
          </v-tooltip>
        </v-speed-dial>
      </v-container>

      <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="total"
        :loading="loading"
        :footer-props="tableFooter"
        :options.sync="options"
        class="mt-16 elevation-10"
        locale="pt-pt"
      >
        <template v-slot:item.user_name="{ item }">
          <span>{{ item.user_name || 'N/A' }}</span>
        </template>

        <template v-slot:item.diary_approved="{item}">
          <v-chip
            color="green"
            text-color="white"
            v-if="item.diary_approved"
          >Aprovada
          </v-chip>
          <v-chip
            color="red"
            text-color="white"
            v-else
          >
            Pendente
          </v-chip>
        </template>

        <template v-slot:item.actions="{item}">
          <IconView
            class="mr-2"
            @click="viewDiaries(item)"
          />
          <IconRemove
            v-if="$root.session.hasPermission(['super', 'diaries.delete'])"
            class="mr-2"
            @on-submit="deleteDiaries(item)"
            :disabled="item.diary_approved == 1"
          />
        </template>
      </v-data-table>
    </div>
    <v-navigation-drawer
      fixed
      right
      v-model="searching"
      hide-overlay
      width="520"
      class="pt-6"
    >
      <v-list>
        <v-list-item>
          <v-btn fab small color="secondary" @click="searching = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-btn absolute right small color="error" @click="resetDiaries">
            Limpar Campos
          </v-btn>
        </v-list-item>
      </v-list>
      <v-list class="pt-10">
        <v-form @submit.prevent="searchDiaries">
          <v-row
            class="mt-10"
            align="center"
            justify="space-around"
          >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
          </v-row>
        </v-form>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Diaries from "@/api/Diaries.js";
import ExportExcelModal from '../../components/diaries/ExportExcel/ExportExcelModal.vue';
import DiariesFilter from '@/components/diaries/DiariesFilter.vue';
import User from '@/api/User.js';

export default {
  components: {
    IconRemove,
    IconView,
    ExportExcelModal,
    DiariesFilter
  },
  watch: {
    'options': {
      immediate: false,
      handler() {
        setTimeout(() => { this.searchDiaries() }, 10);
      },
      deep: true,
    },
  },
  beforeCreate() {
    document.title = "Atlas - Gestão de Diárias";
  },
  data: () => ({
    headers: [
      {
        text: 'Data',
        sortable: false,
        value: 'date',
      },
      {
        text: 'Utilizador',
        value: 'user_name',
        sortable: false
      },
      {
        text: 'Minutos',
        value: 'total_minutos',
        sortable: false
      },
      {
        text: 'Horas',
        value: 'hours',
        sortable: false
      },
      {
        text: 'Aprovação',
        value: 'diary_approved',
        sortable: false
      },
      {
        text: 'Opções',
        value: 'actions',
        sortable: false
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial: false,
    filter: {
      name: null,
      display_name: null,
      user_id: null,
      deleted: false,
      diary_approved: null
    },
    activeUsers: [],
    selectedUser: null,
    showAll: false,
  }),
  mounted() {
    if (!this.$root.session.hasPermission(["super", "diaries.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
    this.fetchActiveUsers();
    this.searchDiaries();
  },
  methods: {
    filterChange(filters) {
      this.filter.user_id = filters.user_id;
      this.filter.diary_approved = filters.status
      console.log("Filtros ajustados:");
      console.log(this.filter);
      this.searchDiaries();
    },

    fillBaseData() {
      const savedData = localStorage["diaries-" + this.$root.session.id];

      if (savedData !== undefined) {
        let data = JSON.parse(savedData);
        this.filter = data.filter;
        this.options = data.options;

        this.selectedUser = data.filter.user_id || null;
        this.showAll = data.showAll;
      }
    },

    async fetchActiveUsers() {
      try {
        const resp = await User.list();
        this.activeUsers = resp.data.filter(user => user.is_active);
      } catch (e) {
        console.error("ERRO", e);
      }
    },

    async searchDiaries(event) {
      if (!!event && event.type === 'submit') {
        this.options.page = 1;
      }

      this.loading = true;

      let filter = { ...this.filter };

      if (this.$root.session.hasPermission(['super']) === false) {
        filter.user_id = this.$root.session.id;
      }

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        deleted: this.filter.deleted
      };

      Object.assign(request, filter);

      localStorage["diaries-" + this.$root.session.id] = JSON.stringify({
        filter: filter,
        options: this.options,
        showAll: this.showAll,
      });

      try {
        const response = await Diaries.search(request);

        let data = response.data;
        this.total = data.total;
        this.items = data.data.map((item) => {
          this.searching = false;
          return item;
        });
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    resetDiaries() {
      this.filter = {
        name: null,
        display_name: null,
        user_id: null,
        deleted: false,
        diary_approved: null
      };

      this.selectedUser = null;
      this.showAll = false;

      this.searchDiaries();
    },

    viewDiaries(item) {
      this.$router.push('diaries/update/' + item.id);
    },

    deleteDiaries(item) {
      Diaries.delete(item.id)
        .then(() => {
          this.searchDiaries();
        });
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Gestão de Diárias',
          disabled: false,
          to: '/diaries',
          exact: true,
        },
      ];
    },
    filteredItems() {
      return this.items;
    }
  }
};
</script>