<template>
    <v-row>
      <!-- Select dos users para as diárias-->
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="selectedUser"
          :items="users"
          item-text="name"
          item-value="id"
          label="Selecionar User"
          outlined dense
          clearable
        ></v-select>
      </v-col>

      <!-- Select estado das diárias-->
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="selectedStatus"
          :items="statusOptions"
          label="Aprovadas ou Pendentes?"
          outlined dense
          clearable
        ></v-select>
      </v-col>

      <!-- Botão lupa -->
      <v-col cols="12" sm="6" md="4">
        <v-btn
          color="primary"
          @click="applyFilters"
          icon
        >
          <v-icon left>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </template>

  <script>
  export default {
    name: 'DiariesFilter',
    props: {
      users: {
        type: Array,
        default: () => []
      },
      initialSelectedUser: {
        type: [Number, String, null],
        default: null
      },
      initialShowAll: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selectedUser: this.initialSelectedUser,
        selectedStatus: this.initialShowAll ? null : false, // Ajuste aqui
        statusOptions: [
          { text: 'Aprovadas', value: true },
          { text: 'Pendentes', value: false }
        ]
      };
    },
    watch: {
      initialSelectedUser(newVal) {
        this.selectedUser = newVal;
      },
      initialShowAll(newVal) {
        this.selectedStatus = newVal ? null : false;
      },
      selectedUser(newVal) {
        if (newVal === null) {
          this.selectedUser = null
        }
      },
      selectedStatus(newVal) {
        if (newVal === null) {
          this.selectedStatus = null
        }
      }
    },
    methods: {
      applyFilters() {
        this.$emit('filter-changed', {
          user_id: this.selectedUser,
          status: this.selectedStatus

        });
      }
    }
  };
  </script>