import BaseApi from "./BaseApi";

export default class Project extends BaseApi {
  construct() {}


  static async filtered(params) {
    let url = Project.getApiUrl() + "filtered";

    return Project.getAxiosInstance().get(url,{params: params});

  }

  static async filteredMultiple(params) {
    let url = Project.getApiUrl() + "filteredmultiple";

    return Project.getAxiosInstance().get(url,{params: params});

  }

    // Esta função é sempre obrigatória
    // Não esquecer de mudar a terminação "admin/QUALQUERCOISA"
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/projects/";
    }
}