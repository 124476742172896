<template>
    <v-dialog v-model="isOpen" max-width="70%" class="mt-4">
      <v-card>

        <v-img
          :src="imageUrl"
          aspect-ratio="1"
          class="white--text"
          contain
          style="max-height: 400px; margin-top: 20px;"
        ></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <script>
  export default {
    name: "ViewImage",
    props: {
      imageUrl: {
        typeof: String,
        default: null
      },
      value: {
        typeof: Boolean,
        default: null
      }
    },
    computed: {
      isOpen: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    },
    methods: {
      closeDialog() {
        this.isOpen = false;
      }
    }
  };
  </script>


