<template>
    <section class="mt-16">
      <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>

        <h1 class="primary--text">
          <v-icon color="primary">mdi-hard-hat</v-icon> <span>Editar Diária</span>
        </h1>

        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>

          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <Diary v-else method="update" :diary="diary" @reload="refresh" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'" />

        </div>
    </section>
  </template>
  <script>

import Diaries from "@/api/Diaries.js";
import Diary from "../../components/diaries/Diary.vue";

  export default {
    components: {
        Diary
    },
    beforeCreate(){
      document.title = "Atlas - Edição de diárias"
    },
    data: () => ({
        diary: {},
        loading:true
    }),
    mounted(){
      if(!this.$root.session.hasPermission(["super", "diaries.update"])) {
        this.$router.push('/admin');
      }

      Diaries.find(this.$route.params.id).then(({data}) => { this.diary = data; this.loading = false;});
    },
    methods:{
      refresh(){
        Diaries.find(this.$route.params.id).then(({data}) => {
          this.diary= data; this.loading = false;
        });
      }
    },
    computed: {
    crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Diárias',
            disabled: false,
            to: '/diaries',
            exact: true,
          },
          {
            text: 'Edição de Diárias',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>