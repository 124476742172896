<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>

        <h1 class="primary--text">
          <v-icon color="primary">mdi-domain</v-icon> <span>Registo de Diárias</span>
        </h1>

        <div class="mt-16">
          <Diary method="create"></Diary>
        </div>
    </section>
  </template>
  <script>


import Diary from '../../components/diaries/Diary.vue';

  export default {
    components: {
        Diary
    },
    beforeCreate(){
      document.title = "Atlas - Registo de Diárias";
    },
    data: () => ({
    }),
    mounted(){

      if(!this.$root.session.hasPermission(["super", "diaries.write"])) {
        this.$router.push('/admin');
      }

    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Diárias',
            disabled: false,
            to: '/diaries',
            exact: true,
          },
          {
            text: 'Registo de Diárias',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>