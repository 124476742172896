import BaseApi from "./BaseApi";

export default class Diaries extends BaseApi{
    construct() {}

    static async search(params) {
        return await Diaries.getAxiosInstance().get(Diaries.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Diaries.getAxiosInstance().post(Diaries.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Diaries.getAxiosInstance().put(Diaries.getApiUrl() + id,params);
    }

    static async approveLines(params, id) {
        return await Diaries.getAxiosInstance().put(Diaries.getApiUrl() + 'approve-diary/' + id,params);
    }

    static async sendImages(params) {
        return await Diaries.getAxiosInstance().post(Diaries.getApiUrl() + 'images/upload', params);
    }

    static async getImages(params) {
        return await Diaries.getAxiosInstance().get(Diaries.getApiUrl() + 'images/get', {params});
    }

    static async checkDate(date) {
        return await Diaries.getAxiosInstance().get(Diaries.getApiUrl() + 'check-date/' + date);
    }

    static async deleteImage(id) {
        return await Diaries.getAxiosInstance().delete(Diaries.getApiUrl() + 'images/delete/' + id);
    }

    static async delete(id) {
        return await Diaries.getAxiosInstance().delete(Diaries.getApiUrl() + id);
    }

    static async find(id) {
        return await Diaries.getAxiosInstance().get(Diaries.getApiUrl() + id);
    }

    static async list(params) {
        let url = Diaries.getApiUrl() + "list";

        return Diaries.getAxiosInstance().get(url,{params: params});
    }

    static async exportExcel(params){
        let url = Diaries.getApiUrl() + 'export-diaries' ;

        return Diaries.getAxiosInstance().post(url, params);
    }

    static async findByDateAndUser(params) {
        return await Diaries.getAxiosInstance().get(Diaries.getApiUrl() + 'find-by-date-and-user', { params });
      }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/diaries/";
    }
}