<template>
    <div>
      <v-dialog v-model="dialog" max-width="700px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="blue" icon v-bind="attrs" v-on="on">
            <v-icon>mdi-file-image-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">Upload de Imagens</v-card-title>

          <v-card-text>
            <v-file-input
              small-chips
              multiple
              label="Associar imagens"
              v-model="uploadedImages"
            ></v-file-input>

            <v-list dense v-if="uploadedImages.length">
              <v-list-item-group>
                <v-list-item v-for="(file, index) in uploadedImages" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ file.name }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-btn icon color="red" @click="removeImage(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <div v-if="fetchedImages.length" class="mt-5">
              <v-row>
                <v-col
                  v-for="(img, idx) in fetchedImages"
                  :key="idx"
                  cols="12" sm="6" md="3"
                >
                  <v-card class="pa-2" outlined>
                    <v-img
                      :src="img.imagesURL"
                      aspect-ratio="1"
                      @click="openImage(img.imagesURL)"
                      class="cursor-pointer"
                    ></v-img>
                    <v-card-actions>
                      <v-btn icon color="red" @click="deleteImage(img.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Fechar
            </v-btn>
            <v-btn color="primary" text @click="submitImages">
              Submeter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ViewImage
        :imageUrl="currentImage"
        v-model="viewDialog"
      />


      <v-snackbar
        v-model="showError"
        color="red"
        top
        right
        timeout="5000"
      >
        {{ errorMessage }}
        <v-btn color="white" text @click="showError = false">
          Fechar
        </v-btn>
      </v-snackbar>
    </div>
  </template>

  <script>
  import Diaries from "@/api/Diaries.js";
  import ViewImage from "@/components/diaries/images/ViewImage.vue";

  export default {
    name: "DropImage",
    components: {
      ViewImage
    },
    props: {
      line_id: String
    },
    mounted() {
      this.fillBaseData();
    },
    data() {
      return {
        dialog: false,
        uploadedImages: [],
        fetchedImages: [],
        viewDialog: false,
        currentImage: null,
        showError: false,
        errorMessage: '',
      };
    },
    methods: {
      fillBaseData() {
        this.fetchImages();
      },
      submitImages() {
        console.log("Imagens enviadas:", this.uploadedImages);
        this.dialog = false;

        const formData = new FormData();
        for (let i = 0; i < this.uploadedImages.length; i++) {
          let file = this.uploadedImages[i];
          formData.append('images[]', file);
        }
        formData.append('line_id', this.line_id);

        Diaries.sendImages(formData)
          .then(() => {
            this.fetchImages();
            this.uploadedImages = [];
          })
          .catch(err => {
            console.error("Erro ao enviar imagens:", err);
            this.errorMessage = "Erro ao enviar imagens.";
            this.showError = true;
          });
      },
      fetchImages() {
        Diaries.getImages({ line_id: this.line_id })
          .then(resp => {
            this.fetchedImages = resp.data;
            console.log(this.fetchedImages);
          })
          .catch(err => {
            console.error("Erro ao buscar imagens:", err);
            this.errorMessage = "Erro ao buscar imagens.";
            this.showError = true;
          });
      },
      deleteImage(id) {
        Diaries.deleteImage(id)
          .then(() => {
            this.fetchedImages = this.fetchedImages.filter(img => img.id !== id);
          })
          .catch(err => {
            console.error("Erro ao deletar imagem:", err);
            this.errorMessage = "Erro ao deletar imagem.";
            this.showError = true;
          });
      },
      handleFileInput(event) {
        this.uploadedImages.push(...event.target.files);
      },
      openImage(imagesUrl) {
        this.currentImage = imagesUrl;
        this.viewDialog = true;
      },
      handleDrop(event) {
        const files = Array.from(event.dataTransfer.files);

        files.forEach((file) => {
          if (file.type.startsWith('image/')) {
            this.uploadedImages.push(file);
          } else {
            console.error(`O arquivo "${file.name}" não é uma imagem válida.`);
          }
        });
      },
      removeImage(index) {
        this.uploadedImages.splice(index, 1);
      },
      closeViewDialog() {
        this.viewDialog = false;
        this.currentImage = null;
      }
    }
  };
  </script>

  <style scoped>
  .drop-zone {
    border: 2px dashed #ccc;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    color: #888;
    cursor: pointer;
    transition: background 0.3s;
  }
  .drop-zone:hover {
    background: #f9f9f9;
  }

  .cursor-pointer {
    cursor: pointer;
  }
  </style>
