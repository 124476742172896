<template>


    <v-dialog
      v-model="dialog"
      max-width="600px"
    >

      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="success"
          icon
          v-bind="attrs"
          v-on="on"
          class="ml-2"
        >
          <v-icon>mdi-file-excel</v-icon>
        </v-btn>

      </template>


      <v-card>
        <v-card-title class="headline">
          Exportar Diárias
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                ref="menu"
                v-model="menuDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">

                  <v-text-field
                    v-model="formattedDateRange"
                    label="Selecione o Período"
                    prepend-inner-icon="mdi-calendar"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="dateRange"
                  range
                  scrollable
                  show-current
                  @input="onRangeSelected"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Fechar
          </v-btn>
          <v-btn color="success" text @click="exportExcel">
            Exportar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </template>

  <script>
  import Diaries from "@/api/Diaries.js";
  import dayjs from "dayjs";

  export default {
    name: "ExportExcelModal",
    data() {
      return {
        dialog: false,
        menuDate: false,
        dateRange: [],
      };
    },

    computed: {

      formattedDateRange() {
        if (this.dateRange && this.dateRange.length === 2) {
          const [start, end] = this.dateRange;


          return `${dayjs(start).format("DD/MM/YYYY")} - ${dayjs(end).format("DD/MM/YYYY")}`;


        }
        return "";
      },
    },

    methods: {
      onRangeSelected() {

        if (this.dateRange.length === 2) {
          this.menuDate = false;
        }
      },

      async exportExcel() {
        try {
          if (!this.dateRange || this.dateRange.length < 2) {

            alert("É necessário escolher o intervalo de datas");
            return;
          }

          const [startDate, endDate] = this.dateRange;

          let data = {
            start: startDate,
            end: endDate,
          }
          await Diaries.exportExcel(data).then((resp)  =>  {
            window.open(resp.data.url, '_blank')
          })



          this.dialog = false;
        } catch (err) {
          console.error(err);
        }
      },
    },
  };
  </script>
