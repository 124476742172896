import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Login2Fa from '../views/Login2Fa.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Layout from '../Layout.vue'
import Dashboard from '../views/Dashboard.vue'
import Clients from '../views/Clients.vue'
import CreateClient from '../components/clients/CreateClient'
import UpdateClient from '../components/clients/UpdateClient'
import Roles from '../views/Roles.vue'
import Permissions from '../views/Permissions.vue'
import Logs from '../views/Logs.vue'
import NotFound from '../views/NotFound.vue'
import Packages from '../views/Packages.vue'
import Collaborators from '../views/Collaborators.vue'
import CreateRole from '../components/roles/CreateRole.vue'
import UpdateRole from '../components/roles/UpdateRole.vue'
import UpdateUser from '../views/settings/users/UpdateUser.vue'
import CreateUser from '../views/settings/users/CreateUser.vue'
import Users from '../views/settings/users/Users.vue'
import Quotes from '../views/Quotes'
import CreateQuote from '../components/quotes/CreateQuote.vue'
import UpdateQuote from '../components/quotes/CreateQuote.vue'
import Suppliers from '@/views/suppliers/Suppliers.vue'
import CreateSupplier from '@/views/suppliers/CreateSupplier.vue'
import UpdateSupplier from '@/views/suppliers/UpdateSuppliers.vue'
import Diaries from '@/views/diaries/Diaries.vue'
import CreateDiaries from '@/views/diaries/CreateDiaries.vue'
import UpdateDiaries from '@/views/diaries/UpdateDiaries.vue'



Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/2fa',
        component: Login2Fa
    },
    {
        path: '/verify-email/:id/:hash',
        component: EmailConfirmation
    },
    {
        path: '/reset/:id/:hash',
        component: ResetPassword
    },
    {
        path: '/admin',
        component: Layout,
        children: [
            {
                path: '/admin',
                component: Dashboard
            },
            {
                path: '/packages',
                component: Packages
            },
            {
                path: '/suppliers/update/:id',
                component: UpdateSupplier
            },
            {
                path: '/suppliers/create',
                component: CreateSupplier
            },
            {
                path: '/suppliers',
                component: Suppliers
            },
           {
                path: '/diaries/update/:id',
                component: UpdateDiaries
            },
            {
                path: '/diaries/create',
                component: CreateDiaries
             },
            {
                path: '/diaries',
                component: Diaries
            },
            {
                path: '/clients',
                component: Clients
            },
            {
                path: '/clients/Create',
                component: CreateClient
            },
            {
                path: '/clients/update/:id',
                component: UpdateClient
            },
            {
                path: '/internal_management/roles',
                component: Roles
            },
            {
                path: '/internal_management/collaborators',
                component: Collaborators
            },
            {
                path: '/internal_management/users',
                component: Users
            },
            {
                path: '/internal_management/permissions',
                component: Permissions
            },
            {
                path: '/internal_management/logs',
                component: Logs
            },
            {
                path: '/admin/settings/roles/create',
                component: CreateRole,
            },
            {
                path: '/admin/settings/roles/:id',
                component: UpdateRole,
            },
            {
                path: '/admin/settings/my-user/:id',
                component: UpdateUser
            },
            {
                path: '/admin/settings/createuser',
                component: CreateUser
            },
            {
                path: '/admin/settings/users/create',
                component: CreateUser,
            },
            {
                path: '/admin/settings/users/:id',
                component: UpdateUser,
            },
            {
                path: '/admin/settings/my-user',
                component: UpdateUser,
            },
            {
                path: '/internal_management/quotes/create',
                component: CreateQuote,
                name: 'create'
            },
            {
                path: '/internal_management/quotes/:id',
                component: UpdateQuote,
                name: 'update'
            },
            {
                path: '/internal_management/quotes',
                component: Quotes,
            },
        ]
    },
    { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
