<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
    >

        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="inputText"
                label="Descrição"
                dense
                outlined
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
                :disabled="disabled"
            ></v-text-field>
        </template>


        <v-card>
            <v-card-title class="headline orange-header mb-5">
               Descrição do Job
            </v-card-title>

            <v-card-text>

                <v-textarea
                    v-model="inputText"
                    label="Descrição do trabalho"
                    dense
                    outlined
                    rows="5"
                ></v-textarea>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="dialog = false">
                    Cancelar
                </v-btn>
                <v-btn color="primary" text @click="saveText">
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "TextEditorModal",
    props: {
        value: String,
        disabled: Boolean,
    },
    data() {
        return {
            dialog: false,
            inputText: this.value,
        };
    },
    watch: {
        value(newValue) {
            this.inputText = newValue;
        },
    },
    methods: {
        saveText() {
            this.$emit("input", this.inputText);
            this.dialog = false;
        },
    },
};
</script>

<style scoped>

.v-textarea {
  cursor: pointer;
}

</style>

